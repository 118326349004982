import React from 'react'
import './Hero.css'
import Header from '../Header/Header';

const Hero = () => {
  return (
    <div className='hero'>
        <div className='left-h'>

            <Header/>

{/* the best ad  */}
            <div className="the-best-ad">
                <div></div>
                <span>the best fitness club in the town</span>
            </div>

            {/* {Hero heading } */}
        <div className="hero-text">
            <div>
                <span className="stroke-text">Shape </span>
                <span>Your</span>
            </div>

            <div>
                <span>Ideal Body</span>
            </div>

            <div>
               <span> 
                in here we will help you to shape and build your idela body and live up your life to fullest
               </span>
            </div>
        </div>

        </div>

        {/* Right side  */}
        <div className='right-h'>right side</div>
    </div>
  );
}

export default Hero