import './App.css';
import Hero from './components/Heros/Hero';
function App() {
  return (
    <div className="App">
        <Hero/>
    </div>
  )
}

export default App;
